import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import { showModal } from "@/lib/modal";
import { store } from "@/store";
const iap = new InAppPurchase2();
//const mmwPremium = "mmw.premium"
const mmwPremium = process.env.VUE_APP_PREMIUM_ID;

export interface InAppPurchase {
  id: string;
  title: string;
  description: string;
  state: string;
  owned: boolean;
  canPurchase: boolean;
  price: string;
  currency: string;
}


function logProduct(tag: string, product: IAPProduct){
  console.log(`${tag}# State: ${product.state}, Can Purchase: ${product.canPurchase}, Owned: ${product.owned}`);
  console.log(product);
}

// initialise in app purchases
document.addEventListener("deviceready", ()=>{
  console.log("deviceReady!!!");
  
  // Register event handlers for the specific product
  iap.when(mmwPremium).registered((product: IAPProduct) => {
    logProduct("Registered", product);
  });

  // Updated
  iap.when(mmwPremium).updated( (product: IAPProduct) => {
    logProduct("Updated", product);
    store.commit("commitPremium", product);
  });

  // User closed the native purchase dialog
  iap.when(mmwPremium).cancelled((product: IAPProduct) => {
      logProduct("Cancelled", product);
  });

  // Product purchase is approved by the app store
  iap.when(mmwPremium).approved((product: IAPProduct)=>{
    // set app to premium mode.
      logProduct("Approved", product);
      product.finish(); // confirm receipt of approved product with app/play store.
  });
  
  iap.when(mmwPremium).owned((product: IAPProduct)=>{
    // set app to premium mode.
      logProduct("Owned", product);
  });

  async function monitorPurchase(){
    console.log("monitorPurchase loop...");
    iap.refresh().finished(()=>{
      const product = iap.get(mmwPremium);
      console.log("Monitor refresh finished:", product);
      if (product.state == iap.INITIATED){
        setTimeout(monitorPurchase, 10000);
      }
    });
  }

  iap.when(mmwPremium).initiated((product: IAPProduct)=>{
    // monitor state when transaction is initiated so that
    // slow purchase cancellations are not lost.
    logProduct("Initiated", product);
    setTimeout(monitorPurchase, 30000);
  });

  // Track all store errors
  iap.error( (err: any) => {
    console.error('Store Error ' + JSON.stringify(err));
    showModal("Store Error " + err.code, err.message, "danger");
  });

  // Run some code only when the store is ready to be used
  iap.ready(() =>  {
    console.log('Store is ready');
    /*
    store.commit("commitMessage", 'Store is ready');
    store.commit("commitMessage", 'Products: ' + JSON.stringify(iap.products));
    store.commit("commitMessage", "MMW Premium: " + JSON.stringify(iap.get(mmwPremium)));
    */
    logProduct("IAP Get", iap.get(mmwPremium));
  });

  iap.register({
    id: mmwPremium,
    type: iap.NON_CONSUMABLE,
  });

  // Refresh the status of in-app products
  iap.refresh();

}, false);

export async function orderPremium() {
  return new Promise((resolve, reject) => {
    iap.order(mmwPremium).then(resolve).error(reject);
  });
}

export async function refreshPurchases(){
  return new Promise((resolve, _reject) => {
    iap.refresh().finished(()=>{
      console.log("Refresh finished.");
      resolve(iap.get(mmwPremium));
    });
  });
}