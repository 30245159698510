<template>
      <ion-grid class="ion-text-center" style="height:100%;">
        <ion-row style = "display: flex; align-items: center; justify-content: center; height: 100%;">
          <ion-col>
            <ion-card>

                <ion-card-content>
  <ion-fab slot="fixed" vertical="top" horizontal="end">
    <ion-fab-button size="small" @click="closePopup">
      <ion-icon :icon="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>
                  <ion-grid class="ion-text-center">
                    <ion-row>
                      <ion-col>
                        <a :href="popupUrl" @click="closePopup">
                          <img :src="popupImage" alt="an advert">
                        </a>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
</template>

<style>
.modal-wrapper{
    background: transparent !important;
}
</style>


<script>

import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonFab,
  IonFabButton,
  modalController,
} from "@ionic/vue";

import { close } from 'ionicons/icons';
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props:{
    popupId: Number,
    popupImage: String,
    popupUrl: String,
  },
  components: { 
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonFab,
    IonFabButton,
    },
  setup(props) {
    const closePopup = () => {
      modalController.dismiss(props.popupId);
    };
    
    return { closePopup, close };
  },
});
</script>
