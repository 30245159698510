import { 
  modalController, 
  } from '@ionic/vue';
import { OverlayEventDetail } from '@ionic/core';
import Popup from '@/components/Popup.vue';

export async function showPopup(id: number, image: string, url: string): Promise<OverlayEventDetail<number>> {
  const modal = await modalController.create({
    component: Popup, //Popup is name of the component to render inside ionic modal
    componentProps: {
      popupId: id,
      popupImage: image,
      popupUrl:url,
    },
  });
  await modal.present();
  return modal.onDidDismiss();
}