import { 
  modalController, 
  } from '@ionic/vue';
import Modal from '@/components/Modal.vue';

export async function showModal(title: string, message: string, messageType = "", dismissText = "OK"): Promise<void> {
  const modal = await modalController.create({
    component: Modal, //Modal is name of the component to render inside ionic modal
    componentProps: {
        title,
        message,
        messageType,
        dismissText,
    },
  });
  return modal.present();
}