import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { 
  apiEndpoints,
  IdOnlyResult, 
  LoginResult, 
  Wedding, 
  LoginParam, 
  ApiError, 
  RegisterParam, 
  isApiError, 
  PlanningPartner, 
  WeddingPartners, 
  ChangePasswordParams, 
  BudgetGroup, 
  Budget, 
  TaskGroup,
  WeddingTask,
  ItemOrder,
  WeddingTaskParams,
  UserPhoto,
  Guest,
  ScheduleItem,
  apiPrefix,
} from '@/lib/api-endpoints'

import { apiRequest } from '@/lib/api-request';
import { InAppPurchase, orderPremium, refreshPurchases } from '@/lib/in-app-purchase';
import { showPopup } from '@/lib/popup';

// define your typings for the store state
export interface State {
  userData: LoginResult | undefined;
  weddingPartners: WeddingPartners;
  budgetGroups: BudgetGroup[];
  budgets: Budget[];
  taskGroups: TaskGroup[];
  tasks: WeddingTask[];
  bgImg: UserPhoto | undefined;
  guests: Guest[];
  schedule: ScheduleItem[];
  premium: InAppPurchase;
  modelsLoaded: string[];
  popupIsActive: boolean;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()


// utility and helper functions used by the store




// to keep the filename constant between save and load.
const backGroundImageFilename = "userBackgroundImage";

export const store = createStore<State>({
  state: {
    userData: undefined,
    weddingPartners:{
      planners: [],
      weddings: [],
    },
    budgetGroups:[],
    budgets:[],
    taskGroups:[],
    tasks:[],
    bgImg: undefined,
    guests:[],
    schedule:[],
    premium:{
      id: "",
      title: "Please Wait...", 
      description: "The details of the premium version are being loaded from the store.",
      state: "invalid",
      owned: (process.env.NODE_ENV == "development"), // enable premium features on development build.
      canPurchase: false,
      price: "",
      currency: "",
    },
    modelsLoaded:[],
    popupIsActive: false,
  },
  mutations:{
    commitUserData(state, userData): void{
      state.userData = userData;
      if (state.userData && state.userData.country == null){
        state.userData.country = undefined;
      }
    },
    commitWedding(state, wedding): void{
      if (state.userData){
        state.userData.wedding = wedding;
      }
    },
    commitWeddingPartners(state, payload){
      state.weddingPartners = payload;
    },
    appendPlanner(state, payload){
      state.weddingPartners.planners.push(payload);
    },
    deletePlanner(state, id){
      state.weddingPartners.planners = state.weddingPartners.planners.filter(function( obj ) {
        return obj.id != id;
      });
    },
    commitBudgetGroups(state, payload){
      state.budgetGroups = payload;
    },
    commitBudgetGroup(state, payload){
      const idx = state.budgetGroups.findIndex( grp => grp.id == payload.id );
      if (idx == -1){
        state.budgetGroups.push(payload);
      } else {
        state.budgetGroups[idx] = payload;
      }
    },
    deleteBudgetGroup(state, payload){
      state.budgetGroups = state.budgetGroups.filter(function( obj ) {
        return obj.id != payload;
      });
    },
    commitBudgets(state, payload){
      state.budgets = payload;
    },
    commitBudget(state, payload){
      const idx = state.budgets.findIndex( el => el.id == payload.id );
      if (idx == -1){
        state.budgets.push(payload);
      } else {
        state.budgets[idx] = payload;
      }
    },
    deleteBudget(state, payload){
      state.budgets = state.budgets.filter(function( obj ) {
        return obj.id != payload;
      });
    },
    commitTaskGroups(state, payload){
      state.taskGroups = payload;
    },
    commitTaskGroup(state, payload){
      const idx = state.taskGroups.findIndex( grp => grp.id == payload.id );
      if (idx == -1){
        state.taskGroups.push(payload);
      } else {
        state.taskGroups[idx] = payload;
      }
      // sort by sort order in case sort_order has been changed.
      state.taskGroups.sort((a,b)=>{ return a.sort_order - b.sort_order }); 
    },
    deleteTaskGroup(state, payload){
      state.taskGroups = state.taskGroups.filter(function( obj ) {
        return obj.id != payload;
      });
    },
    commitTasks(state, payload){
      state.tasks = payload;
    },
    commitTask(state, payload){
      const idx = state.tasks.findIndex( el => el.id == payload.id );
      if (idx == -1){
        state.tasks.push(payload);
      } else {
        state.tasks[idx] = payload;
      }
    },
    deleteTask(state, payload){
      state.tasks = state.tasks.filter(function( obj ) {
        return obj.id != payload;
      });
    },
    commitBgImg(state, payload){
      state.bgImg = payload;
    },
    commitGuests(state, payload){
      state.guests = payload;
    },
    commitGuest(state, payload){
      const idx = state.guests.findIndex( el => el.id == payload.id );
      if (idx == -1){
        state.guests.push(payload);
      } else {
        state.guests[idx] = payload;
      }
    },
    deleteGuest(state, payload){
      state.guests = state.guests.filter(function( obj ) {
        return obj.id != payload;
      });
    },
    commitSchedule(state, payload){
      state.schedule = payload.sort((firstEl: ScheduleItem, secondEl: ScheduleItem) => firstEl.time.localeCompare(secondEl.time));
    },
    commitScheduleItem(state, payload){
      const idx = state.schedule.findIndex( el => el.id == payload.id );
      if (idx == -1){
        state.schedule.push(payload);
      } else {
        state.schedule[idx] = payload;
      }
      // re-sort the array by time
      state.schedule = state.schedule.sort((firstEl, secondEl) => firstEl.time.localeCompare(secondEl.time));
    },
    deleteScheduleItem(state, payload){
      state.schedule = state.schedule.filter(function( obj ) {
        return obj.id != payload;
      });
    },
    commitPremium(state, payload){
      // The (state.premium) object comes from a plugin which seems to modify 
      // the object in-place so that it does not trigger reactivity.
      // To trigger reactivity the payload is always copied to a new object during this commit.
      state.premium = { ... payload };
    },
    clearLoadedModels(state){
      state.modelsLoaded = [];
    },
    addLoadedModel(state, model) {
        state.modelsLoaded.push(model);
        console.log("Loaded model", model, state.modelsLoaded);
        console.log("Premium", state.premium);
    },
  },
  actions:{
    setUserData(context, userData): void{
      if (userData == null){
        userData = undefined; // just to be sure the caller has not actually passed null
        window.localStorage.removeItem('apiToken');
      } else {
        window.localStorage.apiToken = userData.api_token
      }
      context.commit('clearLoadedModels'); // changes to user data invalidate all of the loaded info.
      context.commit('commitUserData', userData);
    },
    async updateWedding(context, weddingParams: Wedding): Promise<ApiError | Wedding> {
      const routeParams = (context.state.userData && context.state.userData.wedding && "id" in context.state.userData.wedding) ? context.state.userData!.wedding!.id!.toString() : undefined;
        const endpoint = (routeParams !== undefined) ? apiEndpoints.updateWedding : apiEndpoints.createWedding;
        const weddingResult: ApiError | Wedding = await apiRequest(endpoint, routeParams, weddingParams);
        if (weddingResult && ! ('errors' in weddingResult)){
          context.commit('commitWedding', weddingResult);
        }
        return weddingResult;
    },
    logOut(context): void {
      context.dispatch('setUserData', undefined);
    },
    async login(context, loginValues: LoginParam): Promise<ApiError | LoginResult> {
      const userData: ApiError | LoginResult = await apiRequest(apiEndpoints.login, undefined, loginValues);
      if ( userData && 'api_token' in userData ) {
        // api_token indicates a valid login
        context.dispatch('setUserData', userData)
      }
      return userData;
    },
    async resetPassword(context, emailValues: {email: string}): Promise<ApiError | {}> {
      const result: ApiError | {} = await apiRequest(apiEndpoints.resetPassword, undefined, emailValues);
      return result;
    },
    async register(context, registerParam: RegisterParam): Promise<ApiError | LoginResult> {
        const userData: ApiError | LoginResult = await apiRequest(apiEndpoints.register, undefined, registerParam);
        if (userData && ! ('errors' in userData)){
            await context.dispatch('setUserData', userData as LoginResult);
        } else {
            await context.dispatch('setUserData', undefined);
        }
        return userData;
    },
    async getUserData(context, options): Promise<undefined | ApiError | LoginResult> {
      options = Object.assign( {force: false, background:false}, options );
      if (context.state.userData && !options.force){
        return context.state.userData;
      } else if (! window.localStorage.apiToken) {
        return undefined;
      } else {
        const result: ApiError | LoginResult = await apiRequest(apiEndpoints.getMyDetails, undefined, undefined, options);
        // the above api call does not retreive the user token, so we need to reload it.
        if (! isApiError(result)){
            const userData: LoginResult = (result as LoginResult);

            // eslint-disable-next-line @typescript-eslint/camelcase
            userData.api_token = context.getters.apiToken;
            await context.dispatch('setUserData', userData as LoginResult);
            
            // show popup if required, whenever the user data is fetched.
            console.log("FETCHED!!!");
            if ((!!userData.popup_image) && ! context.state.popupIsActive){
              context.state.popupIsActive = true
              setTimeout(()=>
                showPopup(
                  userData.popup_id, 
                  apiPrefix + "/.." + userData.popup_image, 
                  userData.popup_url
                ).then(
                  async (value)=>{
                    await context.dispatch('dismissPopup', value.data);
                    context.state.popupIsActive=false;
                  }
                )
                , 1000);
            }
            return userData;

        } else {
          await context.dispatch('setUserData', undefined);
          return undefined;
        }

      }
    },
    async dismissPopup(context, popupId: number): Promise<ApiError | {}> {
      const result: ApiError | {} = await apiRequest(apiEndpoints.dismissPopup, popupId.toString());
      return result;
    },
    async selectWedding(context, weddingId): Promise<ApiError | LoginResult> {
      const userData: ApiError | LoginResult = await apiRequest(apiEndpoints.selectWedding, weddingId.toString());
      // the above api call does not retreive the user token, so we need to reload it.
      if (! isApiError(userData)){
          // eslint-disable-next-line @typescript-eslint/camelcase
          (userData as LoginResult).api_token = context.getters.apiToken;
          await context.dispatch('setUserData', userData as LoginResult);
      }
      return userData;
    },
    async loadWeddingPartners(context): Promise<ApiError | WeddingPartners> {
      
      if (context.state.modelsLoaded.includes('wedding_partners')){
        return context.state.weddingPartners;
      }

      const weddingPartners: ApiError | WeddingPartners = await apiRequest(apiEndpoints.getWeddingPartners);
      if (! isApiError(weddingPartners)){
        context.commit('commitWeddingPartners', weddingPartners as WeddingPartners);
        context.commit('addLoadedModel', 'wedding_partners')
      } else {
        context.commit('commitWeddingPartners', {planners:[], weddings:[]});
      }
      return weddingPartners;
    },
    async addPlanner(context, params): Promise<ApiError | PlanningPartner> {
      const result: ApiError | PlanningPartner = await apiRequest(apiEndpoints.addPlanningPartner, undefined, params);
      if (!isApiError(result)){
        context.commit('appendPlanner', result);
      }
      return result;
    },
    async removePlanner(context, id): Promise<ApiError | IdOnlyResult > {
      const result: ApiError | IdOnlyResult = await apiRequest(apiEndpoints.removePlanningPartner, id);
      if (!isApiError(result)){
        context.commit('deletePlanner', (result as IdOnlyResult).id);
      }
      return result;
    },
    async changePassword(context, pwValues: ChangePasswordParams): Promise<ApiError | LoginResult> {
      const userData: ApiError | LoginResult = await apiRequest(apiEndpoints.changePassword, undefined, pwValues);
      if ( !isApiError(userData) ) {
        // clear userdata and token, the view should ask the user to login again with their new password. 
        context.dispatch('setUserData', undefined);
      }
      return userData;
    },
    async updateUser(context, updateUserParam: RegisterParam): Promise<ApiError | LoginResult> {
      const userData: ApiError | LoginResult = await apiRequest(apiEndpoints.updateUser, context.state.userData!.id.toString(), updateUserParam);
      if (! isApiError(userData)){
          context.commit('commitUserData', userData); // note that because we 'commit' here instead of 'dispatch', the api key remains untouched.
      } 
      return userData;
    },
    async getBudgets(context): Promise< ApiError | Budget[] > {

      if (context.state.modelsLoaded.includes('budgets')){
        return context.state.budgets;
      }

      const budgets: ApiError | Budget[] = await apiRequest(apiEndpoints.getBudgets, undefined, undefined, {background: context.state.budgets.length > 0});
      if (! isApiError(budgets)){
          context.commit('commitBudgets', budgets);
          context.commit('addLoadedModel', 'budgets');
      } 
      return budgets;
    },
    async saveBudgetItem(context, newItem: Budget): Promise< ApiError | Budget > {

      let budget: ApiError | Budget;
      if (newItem.id){
        // update an existing item
        budget = await apiRequest(apiEndpoints.updateBudgetItem, newItem.id.toString(), newItem, {background: true});
      } else {
        // create a new item.
        budget = await apiRequest(apiEndpoints.createBudgetItem, undefined, newItem);
      }
      
      if (! isApiError(budget)){
          context.commit('commitBudget', budget);
      } 
      return budget;

    },
    async deleteBudgetItem(context, budgetId: number): Promise< ApiError | {} > {
      const result: ApiError | {} = await apiRequest(apiEndpoints.deleteBudgetItem, budgetId.toString());
      if (! isApiError(result)){
          context.commit('deleteBudget', budgetId);
      } 
      return result;
    },
    async getBudgetGroups(context): Promise< ApiError | BudgetGroup[] > {

      if (context.state.modelsLoaded.includes('budget_groups')){
        return context.state.budgetGroups;
      } 

      const categories: ApiError | BudgetGroup[] = await apiRequest(apiEndpoints.getBudgetGroups, undefined, undefined, {background: context.state.budgetGroups.length > 0});
      if (! isApiError(categories)){
          context.commit('commitBudgetGroups', categories);
          context.commit('addLoadedModel', 'budget_groups');
      } 
      return categories;
    },
    async createBudgetGroup(context, newGroup: BudgetGroup): Promise< ApiError | BudgetGroup > {
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      newGroup.wedding_id = context.getters.wedding.id;
      const category: ApiError | BudgetGroup = await apiRequest(apiEndpoints.createBudgetGroup, undefined, newGroup);
      if (! isApiError(category)){
          context.commit('commitBudgetGroup', category);
      } 
      return category;
    },
    async updateBudgetGroup(context, group: BudgetGroup): Promise< ApiError | BudgetGroup > {
      const category: ApiError | BudgetGroup = await apiRequest(apiEndpoints.updateBudgetGroup, group.id.toString() , group);
      if (! isApiError(category)){
          context.commit('commitBudgetGroup', category);
      } 
      return category;
    },
    async deleteBudgetGroup(context, groupId: number): Promise< ApiError | {} > {
      const result: ApiError | {} = await apiRequest(apiEndpoints.deleteBudgetGroup, groupId.toString());
      if (! isApiError(result)){
          context.commit('deleteBudgetGroup', groupId);
      } 
      return result;
    },
    async getTaskGroups(context): Promise< ApiError | TaskGroup[]> {
      if (context.state.modelsLoaded.includes('task_groups')){
        return context.state.taskGroups;
      }

      const result: ApiError | TaskGroup[] = await apiRequest(apiEndpoints.getTaskGroups, undefined, undefined, {background: context.state.taskGroups.length > 0});
      if (! isApiError(result)){
          context.commit('commitTaskGroups', result);
          context.commit('addLoadedModel', 'task_groups');
      } 
      return result;
    },
    async createTaskGroup(context, newGroup: TaskGroup): Promise< ApiError | TaskGroup > {
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      newGroup.wedding_id = context.getters.wedding.id;
      const category: ApiError | TaskGroup = await apiRequest(apiEndpoints.createTaskGroup, undefined, newGroup);
      if (! isApiError(category)){
          context.commit('commitTaskGroup', category);
      } 
      return category;
    },
    async updateTaskGroup(context, group: TaskGroup): Promise< ApiError | TaskGroup > {
      const category: ApiError | TaskGroup = await apiRequest(apiEndpoints.updateTaskGroup, group.id.toString() , group);
      if (! isApiError(category)){
          context.commit('commitTaskGroup', category);
      } 
      return category;
    },
    async deleteTaskGroup(context, groupId: number): Promise< ApiError | {} > {
      const result: ApiError | {} = await apiRequest(apiEndpoints.deleteTaskGroup, groupId.toString());
      if (! isApiError(result)){
          context.commit('deleteTaskGroup', groupId);
      } 
      return result;
    },
    async reorderTaskGroups(context, taskOrder: ItemOrder[]){
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      const result: ApiError | TaskGroup[] = await apiRequest(apiEndpoints.reorderTaskGroups, undefined, {task_groups: taskOrder});
      if (! isApiError(result)){
          context.commit('commitTaskGroups', result);
      } 
      return result;
    },
    async getTasks(context): Promise< ApiError | WeddingTask[]> {
      
      if (context.state.modelsLoaded.includes('tasks')){
        return context.state.tasks;
      }


      const result: ApiError | WeddingTask[] = await apiRequest(apiEndpoints.getTasks, undefined, undefined, {background: context.state.tasks.length > 0});
      if (! isApiError(result)){
          context.commit('commitTasks', result);
          context.commit('addLoadedModel', 'tasks');
      } 
      return result;
    },
    async reorderTasks(context, taskOrder: ItemOrder[]){
      const result: ApiError | WeddingTask[] = await apiRequest(apiEndpoints.reorderTasks, undefined, {tasks: taskOrder});
      if (! isApiError(result)){
          context.commit('commitTasks', result);
      } 
      return result;
    },
    async saveTask(context, newItem: WeddingTaskParams): Promise< ApiError | WeddingTask > {

      let task: ApiError | WeddingTask;
      if (newItem.id){
        // update an existing item
        // if the only thing being updated is the task_status then we do it in the background.
        // to simplify the check, if there is a title, do it in the foreground
        task = await apiRequest(apiEndpoints.updateTask, newItem.id.toString(), newItem, {background: !newItem.title});
      } else {
        // create a new item.
        // always set task status to not-done (0) for new tasks.
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        newItem.task_status = 0;
        task = await apiRequest(apiEndpoints.createTask, undefined, newItem);
      }
      
      if (! isApiError(task)){
          context.commit('commitTask', task);
      } 
      return task;
    },
    async deleteTask(context, taskId: number): Promise< ApiError | {} > {
      const result: ApiError | {} = await apiRequest(apiEndpoints.deleteTask, taskId.toString());
      if (! isApiError(result)){
          context.commit('deleteTask', taskId);
      } 
      return result;
    },
    // save locally only, and copy to vuex store
    async saveBgImgLocalOnly(context, photo?){
      if (! photo){
          try {
            await Filesystem.deleteFile({
              path: backGroundImageFilename,
              directory: Directory.Data,
            });
          } catch(ex) {
            console.error(ex);
          }
          context.commit('commitBgImg', undefined);
      }  else {
          await Filesystem.writeFile({
            path: backGroundImageFilename,
            data: JSON.stringify(photo),
            directory: Directory.Data,
            encoding: Encoding.UTF8,
          });
          context.commit('commitBgImg', photo);
      }
    },
    // save locally, to vuex, and to server
    async saveBgImg(context, photo?: UserPhoto): Promise< ApiError | {} >{
      let result: ApiError | {};
      if (! photo){
        result = await apiRequest(apiEndpoints.deletePhoto, context.getters.wedding.id, photo);
      }  else {
        result = await apiRequest(apiEndpoints.storePhoto, undefined, photo, {spinnerTimeout: 60000});
      }
      if (! isApiError(result)){
        await context.dispatch('saveBgImgLocalOnly', photo);
        // update the local hash too so that loadBgImg doesn't
        // delete the freshly saved local copy.
        const wed = context.state.userData && context.state.userData.wedding;
        if (wed){
          if (photo){
            /* eslint-disable-next-line @typescript-eslint/camelcase */
            wed.image_hash = photo.hash;
          } else {
            /* eslint-disable-next-line @typescript-eslint/camelcase */
            wed.image_hash = undefined;
          }
          context.commit("commitWedding", wed);
        }
      }
      return result;
    },
    async loadBgImg (context): Promise<undefined|UserPhoto>{

      // if there is no image hash, clear the image
      if (! store.getters.wedding.image_hash){
        context.commit('commitBgImg', undefined);
        return undefined;
      }

      // if the correct image is already loaded, do nothing.
      if (context.getters.wedding.image_hash == (context.state.bgImg && context.state.bgImg.hash)){
        return context.state.bgImg;
      }

      let fileData: UserPhoto = {
        data: "",
        hash: "",
      }

      try{
        const loadedFile = await Filesystem.readFile({
          path: backGroundImageFilename,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });
        fileData = JSON.parse(loadedFile.data);
        // if the stored image is up to date, then just return it.
        if (fileData.hash == store.getters.wedding.image_hash){
          context.commit('commitBgImg', fileData);
          return fileData;
        }
      } catch(ex) {
        console.error(ex);
      }

      // otherwise load the latest image from the server
      const result: ApiError | UserPhoto = await apiRequest(apiEndpoints.getPhoto, undefined, undefined, {background:true});
      if (! isApiError(result)){
        context.dispatch('saveBgImgLocalOnly', result);
        return result as UserPhoto;
      }
      
      // on error, just return undefined.
      context.commit('commitBgImg', undefined);
      return undefined;
    },
    async getGuests(context): Promise< ApiError | Guest[] > {

      if (context.state.modelsLoaded.includes('guests')){
        return context.state.guests;
      }

      const guests: ApiError | Guest[] = await apiRequest(apiEndpoints.getGuests, undefined, undefined, {background: context.state.guests.length > 0});
      if (! isApiError(guests)){
          context.commit('commitGuests', guests);
          context.commit('addLoadedModel', 'guests');
      } 
      return guests;
    },
    async saveGuest(context, newGuest: Guest): Promise< ApiError | Guest > {
      let guest: ApiError | Guest;
      if (newGuest.id){
        // update an existing item
        guest = await apiRequest(apiEndpoints.updateGuest, newGuest.id.toString(), newGuest, {background: true});
      } else {
        // create a new item.
        guest = await apiRequest(apiEndpoints.createGuest, undefined, newGuest);
      }
      
      if (! isApiError(guest)){
          context.commit('commitGuest', guest);
      } 
      return guest;
    },
    async deleteGuest(context, guestId: number): Promise< ApiError | {} > {
      const result: ApiError | {} = await apiRequest(apiEndpoints.deleteGuest, guestId.toString());
      if (! isApiError(result)){
          context.commit('deleteGuest', guestId);
      } 
      return result;
    },
    async getSchedule(context): Promise< ApiError | ScheduleItem[] > {

      if (context.state.modelsLoaded.includes('schedule')){
        return context.state.schedule;
      }

      const schedule: ApiError | ScheduleItem[] = await apiRequest(apiEndpoints.getSchedule, undefined, undefined, {background: (context.state.schedule.length > 0) || (! context.state.premium.owned)});
      if (! isApiError(schedule)){
          context.commit('commitSchedule', schedule);
          context.commit('addLoadedModel', 'schedule');
      } 
      return schedule;
    },
    async saveScheduleItem(context, newItem: ScheduleItem): Promise< ApiError | ScheduleItem > {
      let item: ApiError | ScheduleItem;
      if (newItem.id){
        // update an existing item
        item = await apiRequest(apiEndpoints.updateScheduleItem, newItem.id.toString(), newItem, {background: true});
      } else {
        // create a new item.
        item = await apiRequest(apiEndpoints.createScheduleItem, undefined, newItem);
      }
      
      if (! isApiError(item)){
          context.commit('commitScheduleItem', item);
      } 
      return item;
    },
    async deleteScheduleItem(context, itemId: number): Promise< ApiError | {} > {
      const result: ApiError | {} = await apiRequest(apiEndpoints.deleteScheduleItem, itemId.toString());
      if (! isApiError(result)){
          context.commit('deleteScheduleItem', itemId);
      } 
      return result;
    },
    async orderPremium(context) {
      try{
        const product = await orderPremium();
        console.log("ordered product", product);
        context.commit("commitPremium", product)
        return product;
      } catch (ex) {
        console.log(ex);
        return undefined;
      }
    },
    async refreshPurchases(context) {
      const product = await refreshPurchases();
      console.log("refreshed product", product);
      context.commit("commitPremium", product)
      return product;
    },
  },
  getters:{
    wedding(state): Wedding {
      return (state.userData && state.userData.wedding) || {
        id: 0,
        date: "2000-01-01T00:00",
        type: 0,
        /* eslint-disable @typescript-eslint/camelcase */
        groom_firstname: "John",
        groom_lastname: "Doe",
        bride_firstname: "Jane",
        bride_lastname: "Doe",
        wedding_location:"",
        image_hash: undefined,
        /* eslint-enable @typescript-eslint/camelcase */
        budget: 0.0 ,
        notification: false,
      };
    },
    userData(state): LoginResult | undefined {
      return state.userData;
    },
    apiToken(): string | undefined {
          return window.localStorage.apiToken;
    },
    budgetGroupTotals: (state) => (importance: number) => {
      const resultMap: { [key: number]: number }  = {};
      for(let i = 0; i < state.budgetGroups.length; i ++){
        resultMap[state.budgetGroups[i].id] =  0.00;
      }

      for(let i = 0; i < state.budgets.length; i ++){
        const budget = state.budgets[i];
        const groupId = budget.budget_group.id
        
        if (budget.importance == importance){
          resultMap[groupId] += parseFloat(budget.amount) * budget.quantity;
        }
      }
      
      return resultMap;

    },
    budgetGroupValues(state,getters){
      return getters.budgetGroupTotals(0);
    },
    wishListTotals(state,getters){
      return getters.budgetGroupTotals(1);
    },
  }
});

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}

