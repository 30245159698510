import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import { store } from '@/store'; // this is intenionally not 'useStore' because we are outside the reactive app.

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    //redirect: '/tabs/home'
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/maintenance',
    component: () => import('@/views/MaintenanceMode.vue')
  },
  {
    path: '/forgot_password',
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/register_wedding',
    component: () => import('@/views/WeddingInfo.vue')
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'home/upload_photo',
        component: () => import('@/views/UploadPhoto.vue')
      },
      {
        path: 'home/settings',
        component: () => import('@/views/Settings.vue')
      },
      {
        path: 'home/settings/wedding',
        component: () => import('@/views/WeddingInfo.vue')
      },
      {
        path: 'home/settings/planners',
        component: () => import('@/views/PlanningPartners.vue')
      },
      {
        path: 'home/settings/mydetails',
        component: () => import('@/views/EditUserDetails.vue')
      },
      {
        path: 'home/settings/changepassword',
        component: () => import('@/views/ChangePassword.vue')
      },
      {
        path: 'budget',
        component: () => import('@/views/Budget.vue')
      },
      {
        path: 'budget/new_category/:budgetCategoryId?',
        component: () => import('@/views/BudgetNewCategory.vue')
      },
      {
        path: 'budget/category/:budgetCategoryId?',
        component: () => import('@/views/BudgetCategory.vue')
      },
      {
        path: 'budget/item/:budgetItemId?/:importance?',
        component: () => import('@/views/BudgetItem.vue')
      },
      {
        path: 'todo',
        component: () => import('@/views/ToDo.vue')
      },
      {
        path: 'todo/item/:todoCategoryId?/:todoItemId?',
        component: () => import('@/views/ToDoItem.vue')
      },
      {
        path: 'todo/list/:todoCategoryId?',
        component: () => import('@/views/ToDoList.vue')
      },
      {
        path: 'guests',
        component: () => import('@/views/Guests.vue')
      },
      {
        path: 'guests/list/:statusId?',
        component: () => import('@/views/GuestList.vue')
      },
      {
        path: 'guests/import',
        component: () => import('@/views/GuestsImport.vue')
      },
      {
        path: 'guests/guest/:guestId?',
        component: () => import('@/views/GuestDetails.vue')
      },
      {
        path: 'bigday',
        component: () => import('@/views/BigDay.vue')
      },
      {
        path: 'bigday/item/:scheduleItemId?',
        component: () => import('@/views/BigDayItem.vue')
      },
    ]
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// redirect to login when not logged in, and
// to home when logged in.
router.beforeEach((to, from, next) => {
  // always allow route to these pages because they do not rely on being logged in, 
  // (the register page will logout user on load.)
  if(to.path == "/register" || to.path == '/maintenance'){
    next();
    return; 
  }

  const apiToken = store.getters.apiToken;
  const userData = store.getters.userData;
  if ((to.path == "/login"  || to.path == "/forgot_password") && userData && apiToken){ 
    // if userData is already loaded, then redirect straight to home.
    // NOTE: The login view will try to load userData from the server
    // and if it is successful, will do it's own redirect.  That is a 
    // different condition to having userData already loaded. 
    next({ path: '/tabs/home' });
    return;
  } else if ((! (apiToken && userData)) && (to.path != "/login") && (to.path != "/forgot_password")){ 
    // if apiToken and userdata are not loaded, go to login page 
    // (which will load userdata and redirect to home if we already have a valid apiToken)  
    next({ path: '/login' })
    return;
  } 

  next();
})

export default router
