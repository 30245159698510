import { App } from "vue";
import countryList from "@/lib/countries";

export type ITranslate = (key: string) => string;

export default function t(key: string): string {
  return key;
}

// convert a number to currency string
export function curr(numVal: number, country: string | undefined): string{

  let symbol = "$";
  if (country){
    for(const countryObj of countryList){
      if (countryObj.isoAlpha2 == country){
        symbol = countryObj.currency.symbol || '$';
        break;
      }
    }

  }

  // for negative values, place the 
  // minus sign before the dollar sign
  if(numVal === undefined){
    return "????";
  }

  if (numVal.toFixed === undefined){
    return "Error(" + numVal + ")";
  }

  let negative = "";
  if (numVal < 0){
    negative = "-";
    numVal *= -1;
  }
  return negative + symbol + numVal.toFixed(2);
}

export const translatePlugin  = {
  install: (app: App, _options: any) => {
    app.config.globalProperties.$t = t;
    app.config.globalProperties.$curr = curr;
  }
}

declare module "@vue/runtime-core" {
  //Bind to `this` keyword
  interface ComponentCustomProperties {
    $t: ITranslate;
  }
}
