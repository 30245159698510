import t from '@/translation';
import { format, parse } from 'date-fns';

export const apiPrefix = process.env.VUE_APP_API_PREFIX;

export interface ApiError {'errors': string[] | string }

export interface ApiEndpoint {
    authNotRequired?: boolean; // assumed to be false if not present.
    method: string;
    endpoint: string;
    loadingMessage: string;
    errorTitle: string;
}

export const apiEndpoints: {[key: string]: ApiEndpoint } = {
    register: {authNotRequired: true, method: 'POST', endpoint: '/user/register', loadingMessage: t('Registering...'), errorTitle: t('Registration Error')},
    login: {authNotRequired: true, method: 'POST', endpoint: '/user/login', loadingMessage: t('Logging In...'), errorTitle: t('Login Error')},
    resetPassword: {authNotRequired: true, method: 'POST', endpoint: '/user/restore_password', loadingMessage: t('Resetting Password...'), errorTitle: t('Error Resetting Password')},
    createWedding: {method: 'POST', endpoint: '/wedding', loadingMessage: t('Creating Wedding...'), errorTitle: t('Error Creating Wedding')},
    updateWedding: {method: 'PUT', endpoint: '/wedding', loadingMessage: t('Updating Wedding...'), errorTitle: t('Error Updating Wedding')},
    getMyDetails: {method: 'GET', endpoint: '/user', loadingMessage: t('Loading User Info...'), errorTitle: t('Error Getting User Details')},
    getWeddingPartners: {method: 'GET', endpoint: '/wedding', loadingMessage: t('Loading Planning Partners...'), errorTitle: t('Error Getting Planning Partners')},
    addPlanningPartner: {method: 'POST', endpoint: '/user/invite', loadingMessage: t('Adding Planning Partner...'), errorTitle: t('Error Adding Planning Partner')},
    removePlanningPartner: {method: 'DELETE', endpoint: '/user/invite', loadingMessage: t('Removing Planning Partner...'), errorTitle: t('Error Removing Planning Partner')},
    selectWedding: {method: 'POST', endpoint: '/user/select_wedding', loadingMessage: t('Selecting Wedding...'), errorTitle: t('Error Selecting Wedding')},
    changePassword: {method: 'POST', endpoint: '/user/change_password', loadingMessage: t('Changing Password...'), errorTitle: t('Error Changing Password')},
    updateUser: {method: 'PUT', endpoint: '/user', loadingMessage: t('Updating Your Details...'), errorTitle: t('Error Updating Details')},
    getBudgets: {method: 'GET', endpoint: '/wedding/budgets', loadingMessage: t('Loading Budgets...'), errorTitle: t('Error Getting Budgets')},
    createBudgetItem: {method: 'POST', endpoint: '/budget', loadingMessage: t('Creating Budget Item...'), errorTitle: t('Error Creating Budget Item')},
    updateBudgetItem: {method: 'PUT', endpoint: '/budget', loadingMessage: t('Updating Budget Item...'), errorTitle: t('Error Updating Budget Item')},
    deleteBudgetItem: {method: 'DELETE', endpoint: '/budget', loadingMessage: t('Deleting Budget Item...'), errorTitle: t('Error Deleting Budget Item')},
    getBudgetGroups: {method: 'GET', endpoint: '/wedding/budget_groups', loadingMessage: t('Loading Budgets...'), errorTitle: t('Error Getting Budgets')},
    createBudgetGroup: {method: 'POST', endpoint: '/budget_group', loadingMessage: t('Creating Category...'), errorTitle: t('Error Creating Category')},
    updateBudgetGroup: {method: 'PUT', endpoint: '/budget_group', loadingMessage: t('Updating Category...'), errorTitle: t('Error Updating Category')},
    deleteBudgetGroup: {method: 'DELETE', endpoint: '/budget_group', loadingMessage: t('Deleting Category...'), errorTitle: t('Error Deleting Category')},
    getTaskGroups: {method: 'GET', endpoint: '/wedding/task_groups', loadingMessage: t('Getting To Do Lists'), errorTitle: t('Error Getting To Do List')},
    createTaskGroup: {method: 'POST', endpoint: '/task_group', loadingMessage: t('Creating To Do Category'), errorTitle: t('Error Creating To Do Category')},
    updateTaskGroup: {method: 'PUT', endpoint: '/task_group', loadingMessage: t('Updating To Do Category'), errorTitle: t('Error Updating To Do Category')},
    deleteTaskGroup: {method: 'DELETE', endpoint: '/task_group', loadingMessage: t('Deleting To Do Category'), errorTitle: t('Error Deleting To Do Category')},
    reorderTaskGroups: {method: 'POST', endpoint: '/task_group/reorder', loadingMessage: t('Reordering Categories'), errorTitle: t('Error Reordering Categories')},
    getTasks: {method: 'GET', endpoint: '/wedding/wedding_tasks', loadingMessage: t('Getting To Do Lists'), errorTitle: t('Error Getting To Do List')},
    createTask: {method: 'POST', endpoint: '/wedding_task', loadingMessage: t('Creating To Do Item'), errorTitle: t('Error Creating To Do Item')},
    updateTask: {method: 'PUT', endpoint: '/wedding_task', loadingMessage: t('Updating To Do Item'), errorTitle: t('Error Updating To Do Item')},
    deleteTask: {method: 'DELETE', endpoint: '/wedding_task', loadingMessage: t('Deleting To Do Item'), errorTitle: t('Error Deleting To Do Item')},
    reorderTasks: {method: 'POST', endpoint: '/wedding_task/reorder', loadingMessage: t('Reordering To Do List'), errorTitle: t('Error Reordering To Do List')},
    storePhoto: {method: 'POST', endpoint: '/photo', loadingMessage: t('Saving Image'), errorTitle: t('Error Saving Image')},
    getPhoto: {method: 'GET', endpoint: '/photo', loadingMessage: t('Loading Image'), errorTitle: t('Error Loading Image')},
    deletePhoto: {method: 'DELETE', endpoint: '/photo', loadingMessage: t('Deleting Image'), errorTitle: t('Error Deleting Image')},
    getGuests: {method: 'GET', endpoint: '/wedding/guests', loadingMessage: t('Loading Guests...'), errorTitle: t('Error Getting Guests')},
    createGuest: {method: 'POST', endpoint: '/guest', loadingMessage: t('Creating Guest...'), errorTitle: t('Error Creating Guest')},
    updateGuest: {method: 'PUT', endpoint: '/guest', loadingMessage: t('Updating Guest...'), errorTitle: t('Error Updating Guest')},
    deleteGuest: {method: 'DELETE', endpoint: '/guest', loadingMessage: t('Deleting Guest...'), errorTitle: t('Error Deleting Guest')},
    getSchedule: {method: 'GET', endpoint: '/wedding/mainday_tasks', loadingMessage: t('Loading Big Day Schedule...'), errorTitle: t('Loading Big Day Schedule')},
    createScheduleItem: {method: 'POST', endpoint: '/mainday_task', loadingMessage: t('Creating Schedule Item...'), errorTitle: t('Error Schedule Item')},
    updateScheduleItem: {method: 'PUT', endpoint: '/mainday_task', loadingMessage: t('Updating Schedule Item...'), errorTitle: t('Error Updating Schedule Item')},
    deleteScheduleItem: {method: 'DELETE', endpoint: '/mainday_task', loadingMessage: t('Deleting Schedule Item...'), errorTitle: t('Error Deleting Schedule Item')},
    
    exportBudgets: {method: 'GET', endpoint: '/budget/export', loadingMessage: t('Downloading Budget...'), errorTitle: t('Error Downloading Budget')},
    exportTasks: {method: 'GET', endpoint: '/wedding_task/export', loadingMessage: t('Downloading Todo List...'), errorTitle: t('Error Downloading Todo List')},
    exportGuests: {method: 'GET', endpoint: '/guest/export', loadingMessage: t('Downloading Guest List...'), errorTitle: t('Error Downloading Guest List')},
    exportSchedule: {method: 'GET', endpoint: '/mainday_task/export', loadingMessage: t('Downloading Big Day Schedule...'), errorTitle: t('Error Downloading Big Day Schedule')},
    
    dismissPopup: {method: 'PUT', endpoint: '/dismiss_popup', loadingMessage: t('Loading User Info...'), errorTitle: t('Error Getting User Details')},

};

export interface LoginParam {
    email: string;
    password: string;
}

export interface IdOnlyResult {
    id: number;
}

export interface Wedding {
    id?: number;
    date: string;
    type: number;
    groom_firstname: string;
    groom_lastname: string;
    bride_firstname: string;
    bride_lastname: string;
    image_hash?: string;
    budget: number; // returned as string "12000.00" 
    notification: boolean; // returned as string "1"
    wedding_location: string;
}

export interface LoginResult {
    id: number;
    email: string;
    api_token: string;
    name: string;
    country: {
        code: string;
        id: number;
        name: string;
        sort: number;
    } | undefined;
    wedding: Wedding | null; // the users currently selected wedding
    my_wedding: Wedding | null; // the users own wedding (may or may not be the selected wedding)
    role: {
        id: number;
        title: string;
    };
    popup_id: number;
    popup_image: string;
    popup_url: string;
} 

export interface RegisterParam {
    name: string;
    email: string;
    country_code: string;
    password: string;
}

export interface PlanningPartner {
    id: number;
    email: string;
    name: string;
} 

export interface WeddingPartners {
    planners: PlanningPartner[];
    weddings: Wedding[];
}

export interface PlannerParams {
    email: string;
    name: string;
}

export interface ChangePasswordParams {
    password: string;
    old_password: string;
}

export interface BudgetGroup {
    id: number;
    wedding_id: number;
    name: string;
    origin: number;
    icon: number;
}

export interface Budget {
    id: number;
    amount: string; //ie. "0.00"
    budget_group: BudgetGroup;
    budget_name: string;
    currency: string;
    importance: number; // 0 = essential, 1 = wish list
    origin: number; // 1 = app generated, 0 = user generated
    paid: number; // amount that has already been paid
    deposit: string; // deposit paid ie. "0.00"
    payed: number; // 1 = this item has been paid for, 0 = not paid for
    quantity: number;
    wedding_id: number; 
}

export interface BudgetParams {
    budget_name: string;
    budget_group_id: number;
    amount: number;
    importance: number;
    paid: number;
    deposit: number;
    quantity: number;
    wedding_id: number; 
    id?: number;
}

export interface TaskGroup {
    id: number;
    name: string;
    origin: number; //(0 or 1)
    sort_order: number;
    wedding_id: number;
}

export interface WeddingTask {
    id: number;
    notes: string;
    origin: number; // (0 or 1)
    sort_order: number;
    recommendation: string;
    task_group: TaskGroup;
    task_status: number; // 0 not done, 1 done.
    title: string;
    wedding_id: number; 
}

export interface ItemOrder {
    id: number;
    sort_order: number;
}

export interface WeddingTaskParams {
    id?: number;
    wedding_id?: number;
    notes: string;
    task_group_id: number;
    task_status?: number; // 0 not done, 1 done.
    title: string;
}

export interface UserPhoto {
    hash: string;
    data: string;
}

export interface Guest {
    id?: number;
    first_name: string;
    last_name: string;
    address_line_1: string;
    address_line_2: string;
    //city: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
    phone: string;
    table: string;
    dietary: string;
    invited: number;
    special_request: string;

    matchesKey?: string;
    matchesValue?: string;
}

export interface ScheduleItem {
    id?: number;
    wedding_id?: number;
    hide_time: boolean;
    time: string; // in the format "YYYY-MM-DD HH:mm:ss" needs a 'T' inserted for apple browsers.
    agenda: string;
    notes: string;
    origin?: number;
}

export function isApiError(arg: any): boolean {
    return (arg && typeof arg === 'object' && ("errors" in arg));
}

export function isUnauth(arg: any): boolean {
    return (isApiError(arg) && arg.errors.includes("Unauthenticated"));
}

export function getErrorMessage(arg: any){
    if (isApiError(arg)){
        if (Array.isArray(arg.errors)){
            return arg.errors[0];
        } else {
            return arg.errors;
        }
    } else {
        return "";
    }
}

// convert date from server string to date object
export function asDateObject(dateString: string){
    return parse(dateString);
}

// convert date from date object to server string
// (which is in the local timezone but has no TZ information attached)
export function asServerString(dateObj: Date){
    return format(dateObj, "YYYY-MM-DD HH:mm:ss");
}


