
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { App } from '@capacitor/app';
import { useStore } from '@/store';
import { router } from '@/router';
import { isApiError } from '@/lib/api-endpoints';


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup(){
    const store = useStore();
    
    // add app event listeners so that we can fetch
    // current data from the api whenever the user opens
    // the app.
    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed to ' + (isActive ? "active." : "inactive."));
      if (isActive){
        store.dispatch("getUserData", {force: true, background: true}).then((result)=>{
          // if the result is an error or undefined (due to no token) then return to the login page.
          if(result == null || isApiError(result)){
            router.push('/login');
          } 
        });
      }
    });

    return { store };

  },
});
