
import { 
  IonLabel, 
  IonTabs, 
  IonTabBar, 
  IonTabButton, 
  IonIcon, 
  IonPage, 
  IonRouterOutlet,
  } from '@ionic/vue';
import { 
  homeOutline,
  //cashOutline,
  logoUsd,
  checkmarkDoneOutline,  
  peopleOutline,
  diamond,
  } from 'ionicons/icons';
import { defineComponent } from '@vue/runtime-core';
import { useStore } from '@/store';

export default defineComponent({
  name: 'Tabs',
  components: { 
    IonLabel, 
    IonTabs, 
    IonTabBar, 
    IonTabButton, 
    IonIcon, 
    IonPage, 
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();
    return {
      store,
      homeOutline,
      //cashOutline,
      logoUsd,
      checkmarkDoneOutline,  
      peopleOutline,
      diamond,
    }
  },
  methods:{
    getIcon(tabName: string): string {
      if (this.$route.path == "/tabs/" + tabName){
        return "assets/icon/active/" + tabName + ".svg";
      }
        
      return "assets/icon/" + tabName + ".svg";

      

    },
  }
});
