<template>
      <ion-grid class="ion-text-center" style="height:100%;">
        <ion-row style = "display: flex; align-items: center; justify-content: center; height: 100%;">
          <ion-col>
            <ion-card>

                <ion-card-header :color="messageType">
                <h6 class="ion-no-margin">{{title}}</h6>
                </ion-card-header>

                <ion-card-content>
                  <ion-grid class="ion-text-center">
                    <ion-row>
                      <ion-col>
                        <ion-text>
                          {{message}}
                        </ion-text>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-button :color="messageType" @click="closeModal">{{dismissText}}</ion-button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>

              </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
</template>

<style>
.modal-wrapper{
    background: transparent !important;
}
</style>


<script>
import {
  IonCard,
  IonCardHeader,
  IonText,
  IonCardContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props:{
    title: String,
    message: String,
    messageType: {type: String, default: ""},
    dismissText: {type: String, default: "OK"},
  },
  components: { 
    IonCard,
    IonCardHeader,
    IonText,
    IonCardContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };

    return { closeModal };
  },
});
</script>
