
import { apiPrefix, ApiEndpoint, ApiError, getErrorMessage, isApiError } from "./api-endpoints";
import { showModal } from "./modal";
import { router } from '@/router';
import { loadingController } from '@ionic/vue';
import t from '@/translation';
import { store } from '@/store'; // this is intentionally not 'useStore' because we are outside the reactive app.

interface ResponseType {
    status: number;
}

interface RequestOptions {
    background?: boolean;
    spinnerTimeout?: number;
}

export function statusCentury(statuscode: number): number {
    return Math.floor(statuscode / 100)
}
export function statusOk(statuscode: number): boolean {
    return statusCentury(statuscode) == 2;
}

// use the standard error box ui to display errors.
export function checkStatus(response: ResponseType, errorMessage: string): boolean {
    if (!statusOk(response.status)) {
        showModal(t("API Error"), errorMessage, "danger");
        return false;
    }
    return true;
}

export async function apiRequest<P, R>(route: ApiEndpoint, routeParams?: [string] | string, bodyParams?: P, requestOptions?: RequestOptions ): Promise<R | ApiError> {

    requestOptions = Object.assign({
        background:false, 
        spinnerTimeout: 10000,
    }, requestOptions);

    const headers: { [key: string]: string } = {
        'Accept': 'application/json'
    };

    let bodyStr;

    if ((route.method.toUpperCase() != "GET") && (route.method.toUpperCase() != "DELETE") && (bodyParams !== undefined)) {
        bodyStr = JSON.stringify(bodyParams);
        headers['Content-Type'] = 'application/json';
    }

    // populate authorization headers if logged in
    const apiToken = store.getters.apiToken;
    if ( (! route.authNotRequired) && (apiToken != undefined) ) {
        headers["Authorization"] = "Bearer " + apiToken;
    }

    // add an app version header so that we can refuse old applications in the future
    headers["MMW-Client-Version"] = "3";

    let endpoint = apiPrefix + route.endpoint;
    if (Array.isArray(routeParams)) {
        routeParams.forEach(val => endpoint += ('/' + val));
    } else if (routeParams !== undefined && routeParams !== "") {
        endpoint += '/' + routeParams;
    }

    console.log("API request to:", endpoint);

    const fetchOptions = {
        method: route.method,
        headers: headers,
        body: bodyStr,
    };

    const loading = await loadingController.create({
        cssClass: 'api-request-loading',
        message: route.loadingMessage,
        duration: requestOptions.spinnerTimeout,
    });

    if (! requestOptions.background){
        await loading.present();
    }

    try {
        const r = await fetch(endpoint, fetchOptions);

        // allow time for pages to transition after this function completes.
        const dismissTimeout = window.setTimeout(()=>{loading.dismiss();}, 1000);
        //loading.dismiss();

        // if we get 401 back from a request, ditch the token, and
        // navigate us to the login page.
        // NOTE: the old api doesn't do this, it replies with {"errors":["Unauthenticated."]}
        // see further along.
        if (r.status == 401) {
            await store.dispatch('logOut');
            //router.push('/');
            window.location.reload(); // this will clear everything, which is what we want.
            return {errors:["Unauthenticated"]}; // just in case.
        }

        // if we get 503 back from a request, the server is in maintenance mode,
        // but we want the maintenance message from the response.
        let maintenanceMode = false;
        if (r.status == 503) {
            maintenanceMode = true;
        }

        // for error reporting.
        const responseClone = r.clone();
        const contentType = r.headers.get('content-type');
        if (contentType != null && contentType.includes('application/json')) {
            try {
                const js = await r.json();
                if (js && isApiError(js)){
                    // dismiss the loading indicator immediately in the
                    // case of an error
                    window.clearTimeout(dismissTimeout);
                    loading.dismiss();
                    if (js.errors.includes("Unauthenticated.")){
                        // this is how the api rejects our token.
                        console.error("Not authenticated!!!");
                        await store.dispatch('logOut');
                        //router.push('/');
                        window.location.reload(); // this will clear everything, which is what we want.
                        return {errors:["Unauthenticated"]}; // just in case.
                        
                    } else if (maintenanceMode) {
                        if (js.errors && (js.errors.length > 0)){
                            router.push(`/maintenance?message=${encodeURIComponent(js.errors[0])}`);
                        } else {
                            router.push('/maintenance');
                        }
                    } else {
                        showModal(t(route.errorTitle), getErrorMessage(js));
                    }
                }
                return js as R;
            } catch (ex) {
                console.error("Error decoding JSON response from apiRequest.");
                console.error(ex.message);
                console.error("Content of the response was:");
                console.error(r);
                const rText = await responseClone.text()
                console.error("Text content of the response body was:");
                console.error(rText);
            }
        } else if (contentType != null && contentType.includes('text/csv')) {
            const csv = await r.text();
            return csv as unknown as R;
        } else if ((process.env.NODE_ENV == "development") && statusOk(r.status) && contentType != null && contentType.includes('text/html')) {
            // if the response was text/html it is likely
            // a dd or message page so open in a new tab,
            // unless it is the 404 redirect to the base html page.
            console.error("API request returned plain HTML, processing....");
            const html = await r.text();
            if (!html.includes('*SPABASE*')) {
                const tab = window.open('about:blank', '_blank');
                if (tab !== null) {
                    tab.document.write(html); // where 'html' is a variable containing your HTML
                    tab.document.close(); // to finish loading the page
                } else {
                    console.error("Unable to open new tab to display error HTML!");
                }
            } else {
                console.error("API request returned the HTML SPA framework.  This means the request endpoint was not found.");
            }
        } 
    } catch (ex) {
        loading.dismiss();

        // errors here indicate some network problem other than HTTP error codes
        // so we can safely assume that we are offline if something is caught here.
        showModal(t("API Error"), ex.message, "danger");
        console.error("Exception in promise of apiRequest fetch stage:");
        console.error(ex);
        return Promise.resolve({ errors: [ex.message] });
    }
            
    // if we have not returned yet, then the function did not know what to do with the response.
    return Promise.resolve({ errors: ["Unable to interpret response from back-end."] });

}

